<template>
	<div>
		<div>
			<v-row>
				<v-col cols="12" md="12" sm="12" :class="{ 'pt-0': !isMdAndUp }">
					<my-page-market-balance-list
						:is-fetch-balance-list="isFetchBalanceList"
					/>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import MyPageMarketBalanceList from './MyPageMarketBalanceList.vue'

export default {
	components: {
		MyPageMarketBalanceList,
	},
	setup() {
		const $vuetify = getVuetify()

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		return {
			isMdAndUp,
		}
	},
}
</script>
