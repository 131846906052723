<template>
	<v-card class="balance-list-wrapper">
		<v-card-title>
			<v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
			리워드 포인트 내역
		</v-card-title>
		<div v-if="balances.length == 0">
			<v-card elevation="0">
				<v-card-title class="d-flex justify-center">
					리워드 포인트 내역이 없습니다
				</v-card-title>
			</v-card>
		</div>
		<div class="balance-list" v-else>
			<v-card
				tile
				v-for="(balance, index) in balances"
				:key="'balance-' + index"
				elevation="0"
			>
				<v-card-text>
					<div class="d-flex justify-space-between">
						<span
							class="transaction-title"
							:class="isSmAndDown ? 'text-sm' : 'text-base'"
						>
							<span v-if="balance.amount > 0">
								{{ balance.title }}
							</span>
							<span v-else>
								<span v-if="balance.marketBalanceWithdrawal">
									{{ balance.marketBalanceWithdrawal.title }}

									<span v-if="balance.marketBalanceWithdrawal.withdrawn">
										<v-chip small color="success">출금완료</v-chip>
									</span>
								</span>
								<span v-else-if="balance.marketBalanceTransfer">
									{{ balance.marketBalanceTransfer.title }}
								</span>
								<span v-else-if="balance.marketBalancePoint">
									{{ balance.marketBalancePoint.title }}
								</span>
								<span v-else>내용 없음</span>
							</span>
						</span>
						<span class="text-lg">
							<span class="text-base">
								{{ balance.amount > 0 ? '입금' : '출금' }}
							</span>
							<strong :class="balance.amount > 0 ? 'payment' : 'withdraw'">
								{{ formatAmount(balance.amount) }}
							</strong>
							원
						</span>
					</div>

					<div class="d-flex justify-space-between">
						<span class="transaction-date">
							{{ balance.transactionDate | transactionTimeFormat }}
						</span>

						<span class="balance">
							잔액
							<strong>{{ balance.balance | commaFormat }}</strong>
							원
						</span>
					</div>
				</v-card-text>
			</v-card>
		</div>
		<div
			class="mx-auto"
			style="position: sticky; bottom: 0; background-color: white"
		>
			<v-pagination
				v-model="currentPage"
				:length="totalPages"
				@input="handlePageChange"
				total-visible="5"
			/>
		</div>
	</v-card>
</template>

<script>
import { onMounted, computed, ref, watch } from '@vue/composition-api'

import dayjs from 'dayjs'

import { getVuetify } from '@core/utils'
import { warningSwal } from '@/plugins/swalMixin'
import { _dateFormat, commaFormat } from '@/filter'

import MarketBalanceService from '@/services/MarketBalanceService'

dayjs.locale('ko')

export default {
	components: {},
	props: {
		isFetchBalanceList: {
			type: Boolean,
		},
	},
	setup(props) {
		const balances = ref([])
		const $vuetify = getVuetify()
		const currentPage = ref(1)
		const totalPages = ref(0)
		const itemsPerPage = ref(10)

		const getBalances = async () => {
			try {
				const payload = {
					page: currentPage.value - 1,
					limit: itemsPerPage.value,
				}

				const { data, totalPagesCount } =
					await MarketBalanceService.listMarketBalances(payload)

				totalPages.value = totalPagesCount
				balances.value = data
			} catch (e) {
				warningSwal({
					html: '포인트 내역을 가져오는데 문제가 발생했습니다.',
					allowOutSideClick: true,
				})
			}
		}

		const formatDate = date => {
			const dayOfWeek = dayjs(date).format('ddd')
			return `${_dateFormat(date)} (${dayOfWeek})`
		}
		const formatAmount = amount => {
			return `${amount < 0 ? '-' : ''}${commaFormat(Math.abs(amount))}`
		}

		const handlePageChange = async value => {
			currentPage.value = value

			await getBalances()
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => props.isFetchBalanceList,
			currentValue => {
				if (currentValue) {
					getBalances()
				}
			},
		)

		onMounted(() => {
			getBalances()
		})

		return {
			balances,
			isSmAndDown,
			currentPage,
			totalPages,
			itemsPerPage,

			formatDate,
			formatAmount,

			handlePageChange,
		}
	},
}
</script>
<style lang="scss" scoped>
.balance-list-wrapper {
	padding-bottom: 50px;
}
.balance-list {
	max-height: 800px;
	position: relative;
	overflow-y: scroll;

	.v-card {
		.v-card__text {
			border-bottom: 1px solid #e6e6e6;
		}
	}

	@media (max-width: 595px) {
		max-height: 650px;
	}

	.transaction-title {
		font-size: 17px !important;

		@media (max-width: 450px) {
			max-width: 200px;
		}
	}

	.transaction-date,
	.balance {
		color: gray;
	}

	.payment {
		color: #297efd;
	}

	.withdraw {
		color: #ff4c51;
	}
}
</style>
